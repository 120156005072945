<template>
  <div>
    <div class="orderInfo-wrapper row justify-content-md-center pt-5">
      <div class="col-md-4 p-3">
        <p class="app-title text-center"><b>Please provide Order Details (optional)</b></p>
        <form name="orderdeatails" v-on:submit.prevent="submitCompleteSurvey()">
          <div class="col p-2 mb-2">
            <input type="text" class="form-control" placeholder="Enter Order ID" v-model="orderId">
          </div>
          <div class="col p-2 mb-2">
            <input type="email" class="form-control" placeholder="Enter Email ID" v-model="emailid">
          </div>
          <div class="col p-2 mb-2">
            <input type="number" v-on:keydown="numberUpdate($event)" class="form-control" placeholder="Enter Phone No" v-model="PhoneNo">
          </div>
          <div class="col d-flex p-0 mt-2">
            <div class="col-12 p-2">
              <button class="btn btn-main btn-block text-uppercase add-opacity pointer">Submit</button>
            </div>
          </div>
        </form>
      </div>   
    </div>
  </div> 
</template>
<script>
import Vue from 'vue'
export default {
  data () {
    return {
      orderId: '',
      emailid: '',
      PhoneNo: '',
      responseObj: {},
      user: {}
    }
  },
  methods: {
    numberUpdate (e) {
      const reg = new RegExp('^[0-9]$')
      if ((this.PhoneNo.length >= 10 || !reg.test(e.key)) && !(e.key === 'Backspace' || e.key === 'Delete' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Tab')) {
        e.preventDefault()
      }
    },
    validatePhone () {
      return !(this.PhoneNo.length > 0 && this.PhoneNo.length < 10)
    },
    validateEmail () {
      let emailVal = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
      return this.emailid.length > 0 && emailVal.test(this.emailid)
    },
    submitCompleteSurvey () {
      this.orderId && this.orderId.length > 0 ? this.user.order_id = this.orderId : ''
      if (this.emailid && this.emailid.length > 0) {
        const isValidEmail = this.validateEmail()
        if (isValidEmail) {
          this.user.email_id = this.emailid
        } else {
          Vue.toasted.show('Please check Email', {
            theme: 'primary',
            className: 'toasted-customred',
            position: 'top-right',
            duration: 5000
          })
          return
        }
      }
      if (this.PhoneNo && this.PhoneNo.length > 0) {
        const isValidPhone = this.validatePhone()
        if (isValidPhone) {
          this.user.phone_no = this.PhoneNo
        } else {
          Vue.toasted.show('Please check Phone No', {
            theme: 'primary',
            className: 'toasted-customred',
            position: 'top-right',
            duration: 5000
          })
          return
        }
      }
      this.responseObj.answer = Vue.ls.get('tss-answers')
      this.responseObj.user = this.user
      this.$store.dispatch('SUBMIT_SURVEY_ANSWERS', this.responseObj)
    }
  }
}
</script>
<style scoped>
.add-opacity {
  opacity: 1;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>